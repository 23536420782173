<template>
  <div>
    <div class="header">
      <img :src="`/static/img/roles/advertismentImage_${$i18n.locale}.png?v=20231020`" />
    </div>
    <div class="contents">
      <v-container>
        <v-row class="topRole">
          <img src="/static/img/roles/underline.png" />
          <v-col
            class="roles"
            v-for="(item, i) in rolesContents"
            :key="i"
            cols="5"
            sm="4"
            md="2"
          >
            <div class="list">
              <router-link :to="item.href" class="text-decoration-none">
                <img class="roleBackground" :src="item.background" />
                <img class="rolesImage" :src="item.img" />
                <div class="name" :class="`name_${$i18n.locale}`">
                  {{ $t(item.name) }}
                </div>
                <img class="nameTag" :src="item.nameImage" />
              </router-link>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { rolesList } from "../configs/characters";
export default {
  data() {
    return {
      rolesContents: rolesList,
    };
  },
};
</script>
<style lang="scss" scoped>
.height100 {
  height: 100%;
}

.header {
  position: relative;
  width: 100%;
  max-height: 500px;
  img {
    display: block;
    width: 100%;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
  }
}
.contents {
  display: relative;
  width: 100%;
  background-image: url("/static/img/roles/characterImg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.container {
  max-width: 1200px;
  padding-bottom: 60px;
}
.topRole {
  margin: 16px 0 0 0;
  p {
    font-size: 60px;
    color: #ffffff;
    font-weight: 900;
    font-style: oblique;
    margin: 0 0 -15px 0;
  }
  img {
    margin-top: 40px;
    display: block;
    width: 100%;
    min-height: 15px;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
  .roles {
    :nth-child(1) {
      margin-left: 115px;
    }
    .list {
      margin: 0;
      text-align: center;
      position: relative;
      .roleBackground {
        display: block;
        width: 100%;
        height: auto;
        max-width: 250px;
        max-height: 400px;
        z-index: 1;
      }
      .rolesImage {
        position: absolute;
        top: 8%;
        margin-left: 119px;
        display: block;
        width: 100%;
        height: auto;
        max-width: 160px;
        max-height: 300px;
        z-index: 2;
      }
      .name {
        position: absolute;
        bottom: 0%;
        color: #fff;
        z-index: 4;
        font-size: 15px;
        margin-bottom: 3px;
        white-space: nowrap;
        text-align: center;
        width: 100%;
        margin-left: 118px;
        max-width: 140px;
        span {
          font-weight: 500;
        }
      }
      .name_en {
        font-size: 14px;
      }
      .nameTag {
        position: absolute;
        bottom: 0%;
        margin-left: 118px;
        display: block;
        width: 100%;
        height: auto;
        max-width: 140px;
        max-height: 80px;
        z-index: 3;
      }
    }
  }
  .roles:nth-child(n + 2) {
    margin-left: -15px;
  }
}
@media only screen and (max-width: 1200px) {
  .topRole {
    p {
      font-size: 40px;
      margin: 0 0 -5px 0;
    }
    .roles {
      :nth-child(1) {
        margin-left: 15px;
      }
      .list {
        position: relative;
        width: 156px;
        .rolesImage {
          top: 10%;
          margin-left: 17px;
          height: 235px;
          width: 147px;
        }
        .name {
          width: 8.3rem;
          right: 10px;
          margin-bottom: 5px;
        }
        .nameTag {
          right: 8px;
          bottom: 0;
          margin-left: 0px;
          height: 100%;
          width: 8.25rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .topRole {
    img {
      margin-top: 0;
    }
    .roles {
      .list {
        width: 140px;
        .rolesImage {
          width: 130px;
          height: 212px;
          margin-left: 18px;
        }
        .nameTag {
          right: 5px;
          width: 7.5rem;
        }
        .name {
          right: 0px;
        }
      }
      .roleBackground {
        max-width: 150px;
        max-height: 300px;
      }
    }
    .roles:nth-child(n + 2) {
      margin-left: 0px;
    }
  }
}
@media only screen and (max-width: 450px) {
  .header {
    img {
      // min-height: 160px;
      width: 150%;
      // min-width: 614px;
      margin-left: -70px;
    }
  }
  .topRole {
    p {
      font-size: 35px;
      margin: 0 0 -5px 0;
    }
    .roles {
      .list {
        .name_en {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
